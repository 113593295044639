import { FC, useEffect, useState } from 'react';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { FormFieldType } from 'constants/kyb';
import { defaultFn } from 'destiny/dist/constants/index';
import {
  ErrorListTemplate,
  FieldErrorTemplate,
  FieldTemplate,
  TitleFieldTemplate,
} from 'components/form/jsonSchemaForm/CustomTemplates';
import DropdownWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/DropdownWidget';
import FileUploaderWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/FileUploaderWidget';
import InfoWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/InfoWidget';
import InputWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/InputWidget';
import RadioListWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/RadioListWidget';
import TabsWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/TabsWidget';

export const defaultWidgetMap = {
  [FormFieldType.TEXT]: InputWidget,
  [FormFieldType.TABS]: TabsWidget,
  [FormFieldType.RADIO_LIST]: RadioListWidget,
  [FormFieldType.INFO]: InfoWidget,
  [FormFieldType.SELECT]: DropdownWidget,
  [FormFieldType.FILE]: FileUploaderWidget,
};

const JsonSchemaForm: FC<any> = ({
  schema = {},
  displayTypes = {},
  customUiSchema = null,
  formData = {},
  widgetMap = defaultWidgetMap,
  formProps = {},
  customProps = {},
  buttons = null,
  additionalTemplates = {},
  onSubmit = defaultFn,
  onChange = defaultFn,
  skeleton = null,
  resetUiSchema = false,
  onUiSchemaSet = defaultFn,
  prefilledFormData = {},
  ...rest
}) => {
  const [uiSchema, setUiSchema] = useState<object | null>(null);

  useEffect(() => {
    if (resetUiSchema) {
      const newUiSchema: any = {};

      Object.keys(displayTypes)?.forEach((propertyKey) => {
        const displayType = displayTypes[propertyKey];

        newUiSchema[propertyKey] = {};

        newUiSchema[propertyKey]['ui:widget'] =
          displayType === FormFieldType.HIDDEN ? 'hidden' : widgetMap?.[displayType];

        newUiSchema[propertyKey]['ui:options'] = {
          ...(displayType === FormFieldType.INFO || displayType === FormFieldType.TABS ? { label: false } : {}),
          ...customProps,
        };
        const uiKeys = schema?.properties?.[propertyKey]
          ? Object.keys(schema?.properties?.[propertyKey]).filter((key) => key.startsWith('ui:'))
          : [];

        uiKeys.forEach((key) => {
          newUiSchema[propertyKey][key] = schema?.properties?.[propertyKey][key];
        });

        if (prefilledFormData?.[schema?.keys?.[propertyKey]]) {
          newUiSchema[propertyKey]['ui:disabled'] = true;
        }
      });
      newUiSchema['ui:order'] = schema?.order;

      setUiSchema(newUiSchema);
      onUiSchemaSet();
    }
  }, [resetUiSchema]);

  const transformErrors = (errors: any) => {
    const updatedErrors = errors?.map((error: any) => {
      if (error?.name === 'enum') error = {};

      return error;
    });

    const hasNoValidError = Object.values(updatedErrors)?.every((error: any) => Object.keys(error)?.length === 0);

    return hasNoValidError ? [] : updatedErrors;
  };

  return (
    <div className='tw-w-full'>
      {!resetUiSchema && uiSchema ? (
        <Form
          schema={schema}
          validator={validator}
          uiSchema={customUiSchema ?? uiSchema}
          formData={formData}
          onSubmit={onSubmit}
          onChange={onChange}
          templates={{
            TitleFieldTemplate,
            FieldTemplate,
            FieldErrorTemplate,
            ErrorListTemplate,
            ...additionalTemplates,
          }}
          transformErrors={transformErrors}
          noHtml5Validate
          omitExtraData
          liveOmit
          {...formProps}
          {...rest}
        >
          {buttons}
        </Form>
      ) : (
        skeleton
      )}
    </div>
  );
};

export default JsonSchemaForm;
